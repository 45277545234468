button {
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
}

button:disabled {
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  cursor: not-allowed;
}

code {
  font-family: 'Roboto Mono', monospace;
}

/**
 * Main
 */
main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}
main div.cell {
  width: 100%;
  padding: 20px;
  flex-direction: column;
}
@media (min-width: 1024px) {
  main div.cell {
    width: 50%;
  }
}
/* Builder */
#builder-container {
  padding: 10px;
  min-height: calc(100vh - 90px);
  border-radius: 3px;
  border-left: 5px solid #111111;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
  background-color: #23272A;
}
#builder-container .embed-group {
  margin-top: 10px;
}
#builder-container .embed-group .embed-group-controls {
  display: flex;
  margin-top: 15px;
}
#builder-container .embed-group .embed-group-controls button {
  width: 50%;
  padding: 15px 0;
  border: 1px solid #111111;
  border-radius: 3px;
  background-color: #18191C;
  color: #EEEEEE;
}
#builder-container .embed-group .embed-group-controls button:hover {
  filter: brightness(110%);
}
#builder-container .embed-group .embed-group-controls button:disabled {
  filter: brightness(70%);
  color: #555;
}
#builder-container .embed-author,
#builder-container .embed-footer {
  display: flex;
  flex-wrap: wrap;
}
#builder-container .embed-author div,
#builder-container .embed-footer div {
  width: 100%;
}
@media (min-width:640px) {
  #builder-container .embed-author div,
  #builder-container .embed-footer div {
    width: 33.33333%;
  }
}
#builder-container label {
  display: flex;
  height: 41px;
  padding: 0 10px;
  border: 1px solid #111111;
  border-radius: 3px;
  background-color: #18191C;
  color: #F5F5F5;
  line-height: 41px;
}
#builder-container label input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  margin: auto 0;
  margin-right: 10px;
  padding: 10px;
  height: 22px;
  border: 1px solid #23272A;
  border-radius: 3px;
  background-color: #18191C;
  color: #F5F5F5;
}
#builder-container label input[type='checkbox']:checked {
  background-color: #F5F5F5;
}
#builder-container label input[type='checkbox']:checked:after {
  content: '\2714';
  position: absolute;
  top: 0;
  left: 5px;
  color: #18191C;
}
#builder-container input[type='text'],
#builder-container input[type='url'],
#builder-container input[type='number'],
#builder-container textarea {
  width: 100%;
  margin: 0;
  padding: 10px;
  border: 1px solid #111111;
  border-radius: 3px;
  background-color: #18191C;
  color: #F5F5F5;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  resize: vertical;
}
#builder-container input[type='text'],
#builder-container input[type='url'],
#builder-container input[type='number'],
#builder-container textarea,
#builder-container label {
  margin-top: 5px;
}
/* Output */
#output-container .controller {
  display: flex;
  margin-bottom: 20px;
  text-align: center;
}
#output-container .controller button {
  width: 50%;
  padding: 15px 0;
  border: none;
  border-radius: 3px;
  background-color: #23272A;
  color: #EEEEEE;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
}
#output-container .controller button:hover {
  transform: translateY(-5px);
  filter: brightness(110%);
  box-shadow: 0 12px 15px rgba(0, 0, 0, .3);
}
#output-container .controller button:first-child {
  margin-right: 10px;
}
#output-container .controller button:last-child {
  margin-left: 10px;
}
#output-container .output {
  min-height: calc(100vh - 160px);
  background-color: black;
  border-radius: 3px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
}
#json-output {
  min-height: calc(100vh - 185px) !important;
  width: 100%;
  background: none;
  border: none;
  font-family: 'Roboto Mono', monospace;
  color: #AAA;
  overflow-x: auto;
}

/**
 * Footer
 */
footer {
  padding: 20px 30px 40px 30px;
}
footer .copyright {
  color: rgba(255, 255, 255, 0.5);
}

/**
 * Code highlight
 */
span.highlight.key {
  color: #cc6666;
}
span.highlight.string {
  color: #99cc99;
}
span.highlight.number {
  color: #cdb05b;
}
span.highlight.boolean {
  color: #cdb05b;
}
